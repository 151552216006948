<template>
  <div v-show="!isHidden" :style="computedStyle">
    <el-form-item
      :prop="name"
      :label="label"
      :class="elFormCssClasses"
      :style="elFormCss"
      :rules="[
        { required: _isRequired, message: $locale.main.message.required_field, trigger: 'blur' },
        rules.controlValues
      ]"
    >
      <span slot="label" :class="labelCssClasses" :style="labelCss">
        {{ label }}
        <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip">
          <i class="el-icon-question"></i>
        </el-tooltip>
      </span>

      <el-date-picker
        :value="value"
        class="date_field_width"
        :type="isMonth ? 'month' : 'date'"
        :name="name"
        :size="size"
        :readonly="readonly"
        :placeholder="placeholder || $locale.main.placeholder.date_field"
        :clearable="clearable"
        :editable="clearable"
        :format="isMonth ? 'MMM.yyyy' : 'dd.MM.yyyy'"
        value-format="yyyy-MM-dd"
        :picker-options="{ firstDayOfWeek: 1 }"
        @input="$emit('input', $event)"
      ></el-date-picker>
    </el-form-item>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import registryMixin from '../registry/registry_mixins'
import controlValues from '../control_values_mixin'
import InputLabel from '@/mixins/inputLabel.js'

export default {
  name: 'a-date',
  inject: {
    forceUpdateSettingsPanel: {
      default: () => () => {}
    }
  },
  mixins: [mixin, registryMixin, controlValues, InputLabel],
  props: {
    editorAlias: {
      type: String,
      description: 'Псевдоним'
    },
    controlValues: {
      type: Object,
      editor: 'ControlValues',
      description: 'Контроль значений',
      default: () => {
        return {
          operator: null,
          type: 'date',
          conditions: []
        }
      },
      options: { type: 'date' }
    },
    name: {
      type: String,
      description: 'Атрибут',
      options: {
        removeSpaces: true
      }
    },
    defaultValue: {
      type: Boolean,
      description: 'Сегодняшняя дата по умолчанию'
    },
    defaultValueModifier: {
      type: Number,
      description: 'Укажите количество дней до/после сегодняшней даты'
    },
    label: {
      type: String,
      description: 'Название'
    },
    placeholder: {
      type: String,
      description: 'Плейсхолдер'
    },
    tooltip: {
      type: String,
      description: 'Подсказка'
    },
    isMonth: {
      description: 'Только месяца',
      type: Boolean
    },
    isShow: {
      type: Boolean,
      description: 'Скрыть'
    },
    clearable: {
      type: Boolean,
      default: true,
      description: 'Очищаемое'
    }
  },
  computed: {
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (this.width && !this.block) {
        css += ';width:' + this.width
      }
      if (!this.block) {
        css += `;display: inline-block; width:${this.width || '200px'}`
      }
      if (this.wrapper) {
        css += ';display: block;'
      }

      return css
    }
  },
  watch: {
    editorAlias () {
      this.forceUpdateSettingsPanel()
    }
  },
  mounted () {
    if (this.defaultValue) {
      let date = new Date()
      let days = this.defaultValueModifier ? this.defaultValueModifier : 0
      date.setDate(date.getDate() + days)
      let dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      this.$emit('input', dateString)
    }
  }
}
</script>

<style scoped>
  .date_field_width.el-date-editor.el-input{
    width: 100%;
  }
</style>
